import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSelectedWallets,
  setRedumptionRatio,
} from "../store/Profile/ProfileActions";
import {
  deleteFromCart,
  setOrderTypeModal,
  setWalletRedeemed,
  updateCart,
} from "../store/Cart/CartActions";
import { v4 as uuidv4 } from "uuid";
import { dispatchErrorMessageAsWarning } from "../utils/Shared";

const useWalletPoints = () => {
  const cart = useSelector((state) => state.cart);
  const { userData } = useSelector((state) => state.hotel);
  const { redumptionRatio, usedWalletPoints } = useSelector(
    (state) => state.profile
  );
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const getWalletRedemption = () => {
    let obj = {};

    if (cart?.checkoutDetails?.orderType === "DELIVERY") {
      obj = userData?.redumption_ratio?.find(
        (rat) => rat?.order_type === "ONLINE_DELIVERY"
      );
    } else if (cart?.checkoutDetails?.orderType === "PICKUP") {
      obj = userData?.redumption_ratio?.find(
        (rat) => rat?.order_type === "ONLINE_PICKUP"
      );
    } else if (cart?.checkoutDetails?.orderType == null) {
      dispatch(setOrderTypeModal(true));
    }
    return obj;
  };

  const getPriceWithAvailableWalletPoints = (points) => {
    let price = 0;
    const redump = getWalletRedemption();
    if (redump) {
      price = points / redump?.ratio_points;
    }
    const roundedPrice = price;

    return roundedPrice;
  };

  const isPointMoreThanMax = (points) => {
    if (getWalletRedemption()?.maximum_consumable_points < points) {
      return false;
    } else {
      return true;
    }
  };

  const isPointLessThanMin = (points) => {
    if (getWalletRedemption()?.min_consumable_points > points) {
      return false;
    } else {
      return true;
    }
  };

  const isPointIsMoreThanAvailable = (points) => {
    if (points > userData?.wallet_points) {
      return false;
    } else {
      return true;
    }
  };

  const getWalletPointsFromCartPrice = (price, points) => {
    let walletPoints = 0;
    let walletPrice = 0;
    let status = false;
    if (getWalletRedemption()?.ratio_points) {
      walletPoints = price * getWalletRedemption()?.ratio_points;
      walletPrice = points / getWalletRedemption()?.ratio_points;
      status = true;

      if (price > 0) {
        if (price < walletPrice) {
          const adjustedWalletPoints =
            (price - 1) * getWalletRedemption()?.ratio_points;

          // Calculate walletPrice based on the adjusted walletPoints
          const adjustedWalletPrice =
            adjustedWalletPoints / getWalletRedemption()?.ratio_points;

          return {
            status: false,
            walletPoints: adjustedWalletPoints,
            walletPrice: adjustedWalletPrice,
          };
        } else {
          return { status: true, walletPoints, walletPrice };
        }
      }
    }
  };

  const calculateTotalPrice = (filteredProds) => {
    let totalPrice = 0;

    filteredProds?.forEach((prod) => {
      let productPrice = prod.sale_price; // Default to sale_price

      // Check if a size is specified and sizes array is not empty
      if (prod?.size && prod?.sizes?.length > 0) {
        const sizeDetail = prod?.sizes.find((size) => size?.id == prod?.size);
        if (sizeDetail) {
          productPrice = sizeDetail.size_amount; // Use size_amount if available
        }
      }

      // Add the product price and addOns price to the total
      totalPrice += productPrice * prod?.count;
    });

    return totalPrice;
  };

  const getWalletPointsFromCartProducts = (points) => {
    let walletPoints = 0;
    let walletPrice = 0;
    let status = false;

    const filteredProds = filterCartProductsLoyality(cart?.data);
    const totalPrice = calculateTotalPrice(filteredProds);
    walletPoints = totalPrice * getWalletRedemption()?.ratio_points;
    walletPrice = points / getWalletRedemption()?.ratio_points;
    status = true;

    if (totalPrice > 0) {
      if (totalPrice < walletPrice) {
        const adjustedWalletPoints =
          (totalPrice - 1) * getWalletRedemption()?.ratio_points;

        // Calculate walletPrice based on the adjusted walletPoints
        const adjustedWalletPrice =
          adjustedWalletPoints / getWalletRedemption()?.ratio_points;

        return {
          status: false,
          walletPoints: adjustedWalletPoints,
          walletPrice: adjustedWalletPrice,
        };
      } else {
        return { status: true, walletPoints, walletPrice };
      }
    } else {
      return { status: false, walletPoints, walletPrice };
    }
  };

  const PointsUsedForType2 = () => {
    let status = false;
    let points = 0;
    let amount = 0;

    if (cart?.data && cart.data.length > 0) {
      cart.data.forEach((item) => {
        if (item.walletType === 2) {
          amount += Number(item.walletAmount);
          points += Number(item.walletUsedForProduct);
          status = true;
        }
      });
    }

    return { status, points, amount };
  };

  const PointsToRedeemIfMax = (points) => {
    let redeemablePoints = 0;
    let redum = getWalletRedemption();

    // if (cart?.discountType !== "wallet") {
    if (!getWalletPointsFromCartProducts(points)?.status) {
      if (!PointsUsedForType2()?.status) {
        if (
          getWalletPointsFromCartProducts(points)?.walletPoints >
            redum?.maximum_consumable_points &&
          getWalletPointsFromCartProducts(points)?.walletPoints >
            redum?.min_consumable_points
        ) {
          redeemablePoints = redum?.maximum_consumable_points;
        } else {
          redeemablePoints =
            getWalletPointsFromCartProducts(points)?.walletPoints;
        }
      } else {
        if (
          getWalletPointsFromCartProducts(points)?.walletPoints >
          redum?.maximum_consumable_points - PointsUsedForType2()?.points
        ) {
          redeemablePoints =
            redum?.maximum_consumable_points - PointsUsedForType2()?.points;
        } else {
          redeemablePoints =
            getWalletPointsFromCartProducts(points)?.walletPoints;
        }
      }
    } else if (
      points >
        redum?.maximum_consumable_points - PointsUsedForType2()?.points &&
      getWalletPointsFromCartProducts(points)?.status
    ) {
      redeemablePoints =
        redum?.maximum_consumable_points - PointsUsedForType2()?.points;
    } else {
      redeemablePoints = points;
    }
    // }
    const roundedWallet = redeemablePoints;
    return roundedWallet;
  };

  const getPriceTotalDiscountFromCart = () => {
    let total = 0;
    if (cart?.data && cart?.data?.length > 0) {
      cart?.data?.map((item) => {
        if (item?.walletAmount) {
          total += item?.walletAmount;
        }
      });
    }
    return total;
  };

  const getWalletPointsFromPrice = (price) => {
    let points = 0;
    if (getWalletRedemption()?.ratio_points) {
      points = price * getWalletRedemption()?.ratio_points;
    }
    return points;
  };

  const checkIsLoyalityProdCanApply = (price, count) => {
    if (user) {
      if (usedWalletPoints < getWalletRedemption()?.maximum_consumable_points) {
        if (
          getWalletPointsFromPrice(
            getPriceWithAvailableWalletPoints(userData?.wallet_points)
          ) -
            usedWalletPoints >=
          getWalletPointsFromPrice(price * count)
        ) {
          if (
            getWalletPointsFromPrice(price * count) <=
              getWalletRedemption()?.maximum_consumable_points &&
            getWalletPointsFromPrice(price * count) >=
              getWalletRedemption()?.min_consumable_points
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getWalletPointsFromProductPrice = (price) => {
    const walletPoints = price * getWalletRedemption()?.ratio_points;

    return walletPoints;
  };

  const filterCartProductsLoyality = (array) => {
    const filteredArray = array?.filter(
      (item) =>
        !item?.combo_id &&
        !item?.isWalletRedeemed &&
        item?.loyalty_credit_disable != 1
    );
    return filteredArray;
  };

  const checkIsProductLoyalityApplied = () => {
    const isApplied = cart?.data?.some(
      (item) => item.isWalletRedeemed === true
    );
    return isApplied;
  };

  const resetWalletProducts = (type) => {
    if (type == 1) {
      const itemsWithoutCombo = cart?.data?.filter((dat) => !dat?.combo_id);
      const itemsWithTypeOne = itemsWithoutCombo?.filter(
        (item) => item?.walletType == 1
      );
      if (itemsWithTypeOne) {
        itemsWithTypeOne?.forEach((prod) => {
          dispatch(
            updateCart({
              ...prod,
              isWalletRedeemed: false,
              walletDiscount: 0,
              walletAmount: 0,
              walletType: 0,
              walletUsedForProduct: 0,
            })
          );
        });
      }
      dispatch(resetSelectedWallets());
      dispatch(setWalletRedeemed(false));
      // dispatchErrorMessageAsWarning({
      //   title: "Warning",
      //   message: "Sorry, removed wallet discount applied",
      // });
    } else if (type === 2) {
      const itemsWithoutCombo = cart?.data?.filter((dat) => !dat?.combo_id);
      const itemsWithTypeOne = itemsWithoutCombo?.filter(
        (item) => item?.isWalletRedeemed
      );
      if (itemsWithTypeOne) {
        itemsWithTypeOne?.forEach((prod) => {
          dispatch(
            updateCart({
              ...prod,
              isWalletRedeemed: false,
              walletDiscount: 0,
              walletAmount: 0,
              walletType: 0,
              walletUsedForProduct: 0,
            })
          );
        });
        dispatch(resetSelectedWallets());
      }
      dispatch(setWalletRedeemed(false));
      // dispatchErrorMessageAsWarning({
      //   title: "Warning",
      //   message: "Sorry, removed wallet discount for products applied",
      // });
    }
  };

  const checkIsWalletDiscountTypeIsOne = () => {
    if (cart?.data && cart?.data?.length > 0) {
      return cart?.data?.some((obj) => obj?.walletType === 1);
    }
  };

  const walletPointsOtherThanProduct = () => {
    let total = 0;
    if (cart?.data && cart?.data?.length > 0) {
      cart?.data?.map((item) => {
        if (item?.walletType == 1) {
          total += item?.walletUsedForProduct;
        }
      });
    }
    return total;
  };

  const walletPointsFromType2 = () => {
    let total = 0;
    if (cart?.data && cart?.data?.length > 0) {
      cart?.data?.map((item) => {
        if (item?.isWalletRedeemed) {
          if (item?.walletType) {
            total += item?.walletUsedForProduct;
          }
        }
      });
    }
    return total;
  };

  const walletAmountOtherThanProd = () => {
    let total = 0;
    if (cart?.data && cart?.data?.length > 0) {
      cart?.data?.map((item) => {
        if (item?.walletType == 1) {
          const amount = parseFloat(item?.walletAmount);
          if (!isNaN(amount)) {
            total += amount;
          }
        }
      });
    }
    return total;
  };

  function splitDigits(value) {
    // Convert the value to a number if it's not already
    const numberValue = parseFloat(value);

    // Check if the value is a valid number
    if (!isNaN(numberValue)) {
      // Convert the number to a string and split it at the decimal point
      const parts = numberValue.toFixed(2).toString().split(".");

      // If there are two parts (integer and decimal)
      if (parts.length === 2) {
        return parts[1]; // Return the decimal part
      }
    }

    // If value is not a valid number or doesn't have decimal part, return empty string
    return "";
  }

  const RemoveItemCoupon = (coupon) => {
    if (coupon?.items?.length > 0) {
      cart?.data?.forEach((category) => {
        if (category?.isAutoAdded) {
          dispatch(deleteFromCart(category));
        }
        // else {
        //   dispatch(
        //     updateCart({
        //       ...category, // Pass the selected item's details

        //       categoryId: category.categoryId,
        //     })
        //   );
        // }
      });
    }
  };

  const getTotalWalletPoints = () => {
    let total = 0;

    cart?.data?.map((item) => {
      if (item?.walletUsedForProduct) {
        total += item?.walletUsedForProduct;
      }
    });
    return total;
  };

  const checkWalletPointsConditions = () => {
    let total_walletPoints = getTotalWalletPoints();
    let redem = getWalletRedemption();

    if (total_walletPoints && redem) {
      if (total_walletPoints > redem?.maximum_consumable_points) {
        return false;
      } else if (total_walletPoints < redem?.min_consumable_points) {
        return false;
      } else if (total_walletPoints > userData?.wallet_points) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return {
    checkWalletPointsConditions,
    getWalletRedemption,
    isPointIsMoreThanAvailable,
    isPointMoreThanMax,
    isPointLessThanMin,
    getPriceWithAvailableWalletPoints,
    PointsToRedeemIfMax,
    checkIsLoyalityProdCanApply,
    getWalletPointsFromCartPrice,
    getWalletPointsFromProductPrice,
    filterCartProductsLoyality,
    checkIsProductLoyalityApplied,
    getWalletPointsFromCartProducts,
    calculateTotalPrice,
    resetWalletProducts,
    checkIsWalletDiscountTypeIsOne,
    walletAmountOtherThanProd,
    walletPointsOtherThanProduct,
    splitDigits,
    walletPointsFromType2,
    RemoveItemCoupon,
  };
};

export default useWalletPoints;
