// Constants
import { useDispatch } from "react-redux";
import { MESSAGES } from "../../utils/Constants";
import * as ACTIONS from "./LocationActions";
import { call, put, takeLatest, select } from "redux-saga/effects";

export const calculateDistanceDuration = async (
  hotel,
  distanationLocation,
  isCheck,
  total
) => {
  const sortDeliveryZones = (deliveryzones) => {
    return deliveryzones?.sort((a, b) => {
      if (a.zone_area !== b?.zone_area) {
        return a.zone_area - b?.zone_area;
      } else {
        return b.minimum_amount - a?.minimum_amount;
      }
    });
  };

  const findBestZone = (sortedZones, distance, total) => {
    let matchedZone = null; // Initialize matchedZone variable
    let freeZone = null; // Initialize freeZone variable
    let difference = 0;
    for (let i = 0; i < sortedZones?.length; i++) {
      const zone = sortedZones[i];

      // Check if the order amount meets the minimum and the zone area is appropriate
      if (total >= zone?.minimum_amount && zone.zone_area >= distance) {
        matchedZone = matchedZone ? matchedZone : zone; // Assign the first matched zone to matchedZone
      }
      if (zone.zone_area >= distance && zone?.delivery_fee === 0) {
        freeZone = freeZone ? freeZone : zone; // Assign the first free zone to freeZone
      }
    }

    if (matchedZone && freeZone) {
      // Calculate the difference between the total and the minimum_amount
      difference = (freeZone.minimum_amount - total).toFixed(2);
      // Dispatch an action to store the difference in the Redux store
    }

    // Sort matched zones by delivery fee in ascending order
    sortedZones
      .filter(
        (zone) => total >= zone.minimum_amount && zone.zone_area >= distance
      )
      .forEach((zone) => {
        matchedZone =
          matchedZone && matchedZone.delivery_fee <= zone.delivery_fee
            ? matchedZone
            : zone;
      });

    return { matchedZone, freeZone, difference };
  };

  return new Promise((resolve, reject) => {
    if (!window.google) {
      return reject("Google api is not initialized");
    }
    if (!hotel) {
      return reject("Hotel not found");
    }
    if (!hotel.latitude || !hotel.longitude) {
      return reject("Hotel location not found");
    }
    if (!distanationLocation.latitude || !distanationLocation.longitude) {
      return reject("Destination location not found");
    }
    const mapsApi = window.google.maps;
    const origin = new mapsApi.LatLng(hotel.latitude, hotel.longitude);
    const destination = new mapsApi.LatLng(
      distanationLocation.latitude,
      distanationLocation.longitude
    );
    const service = new mapsApi.DistanceMatrixService();

    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: "DRIVING",
      },
      (response, status) => {
        if (status === "OK") {
          if (!response?.rows?.[0].elements?.[0]) {
            return reject("No response found from google map");
          }

          const { status, distance, duration } = response.rows[0].elements[0];

          if (status === "ZERO_RESULTS") {
            return reject("No response found from google map");
          }
          if (!distance || !duration) {
            return reject("No response found from google map");
          }
          // if (distance) {

          const formattedDistance = Number((distance.value / 1000).toFixed());
          const formattedDuration = duration.text;

          const sortedDeliveryZones = sortDeliveryZones(hotel?.deliveryzones);
          const zone = hotel?.deliveryzones?.find(
            (z) => z.zone_area >= formattedDistance
          );

          const { matchedZone, freeZone, difference } = findBestZone(
            sortedDeliveryZones,
            formattedDistance,
            total
          );

          if (!isCheck && !zone) {
            return reject(MESSAGES.INVALID_LOCATION);
          }
          // if ()
          resolve({
            distance: formattedDistance,
            duration: formattedDuration,
            zone: freeZone ? freeZone : matchedZone,
            difference: difference ? difference : null,
          });
          // } else {
          //   reject("Something went wrong while process your location");
          // }
          // resolve({
          //   distance: formattedDistance,
          //   duration: formattedDuration,
          // });
        } else {
          return reject("Something went wrong while process your location");
        }
      }
    );
  });
  // return new Promise((resolve, reject) => {
  //   if (!window.google) {
  //     reject("Google api is not initialized");
  //   } else if (!hotel) {
  //     reject("Hotel not found");
  //   } else if (!hotel.latitude || !hotel.longitude) {
  //     reject("Hotel location not found");
  //   } else if (
  //     !distanationLocation.latitude ||
  //     !distanationLocation.longitude
  //   ) {
  //     reject("Destination location not found");
  //   } else {
  //     const mapsApi = window.google.maps;
  //     const origin = new mapsApi.LatLng(hotel.latitude, hotel.longitude);
  //     const destination = new mapsApi.LatLng(
  //       distanationLocation.latitude,
  //       distanationLocation.longitude
  //     );
  //     const service = new mapsApi.DistanceMatrixService();
  //     service.getDistanceMatrix(
  //       {
  //         origins: [origin],
  //         destinations: [destination],
  //         travelMode: "DRIVING",
  //       },
  //       (response, status) => {
  //         if (status === "OK") {
  //           if (response?.rows?.[0].elements?.[0]) {
  //             reject("No response found from google map");
  //           } else {
  //             const { status, distance, duration } =
  //               response.rows[0].elements[0];

  //             if (status === "ZERO_RESULTS") {
  //               reject("No response found from google map");
  //             } else if (!distance || !duration) {
  //               reject("No response found from google map");
  //             } else {
  //               const formattedDistance = Number(
  //                 (distance.value / 1000).toFixed()
  //               );
  //               const formattedDuration = duration.text;

  //               const zone = hotel.deliveryzones.find(
  //                 (z) => z.zone_area >= formattedDistance
  //               );
  //               if (!zone) {
  //                 reject(MESSAGES.INVALID_LOCATION);
  //               }
  //               resolve({
  //                 distance: formattedDistance,
  //                 duration: formattedDuration,
  //               });
  //             }
  //           }
  //         } else {
  //           reject("Something went wrong while process your location");
  //         }
  //       }
  //     );
  //   }
  // });
};
