import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  checkoutRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  root: {
    boxSizing: "border-box",
    width: "100%",
    marginBottom: theme.spacing(2.4),
    padding: theme.spacing(2.4),
    borderRadius: "12px",
    backgroundColor: theme.palette.common.white,
  },
  headerTitle: {
    fontWeight: "bold",
    color: theme.palette.common.normalTitle,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "19px",
    // marginBottom: theme.spacing(2.4),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  formDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  input: {
    marginRight: theme.spacing(2), // Adjust the spacing as needed
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "12px",
    padding: theme.spacing(0.5),
  },
  disabledButton: {
    backgroundColor: theme.palette.grey[300], // Customize the style for the disabled button
  },
  scannerDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "80%",
    paddingTop: "10px",
  },
  toRedeemText: {
    color: "black",
    fontWeight: "400",
  },
  walletBtnDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  walletTitleDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "20px",
  },
  coinIcon: {
    color: "goldenrod !important",
    marginLeft: "6px",
  },
  redeemedText: {
    color: "green",
    fontWeight: "600",
  },
  scannerCloseBtn: {
    backgroundColor: "#e63946",
    color: "white",
    transition: "background-color 0.3s", // Add a smooth transition
    "&:hover": {
      backgroundColor: "#f77f88", // Light red color on hover
    },
  },
}));

export default useStyles;
