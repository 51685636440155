import { useCallback } from "react";
import { useSelector } from "react-redux";

function useProduct() {
  const { hotel } = useSelector((state) => state.hotel);
  const { selectedCoupon } = useSelector((state) => state.cart);

  // const originalPrice = useMemo(() => {
  //   if(!product || !hotel){
  //       return 0;
  //   }
  //   const calTax = ( Number(product.sale_price) * 3) / 23
  //   const tax = hotel.tax_exclusive === 1 ? (Number(product.sale_price) - calTax) : Number(product.sale_price)
  //   return tax.toFixed(2) || 0
  // },[product,hotel]);

  // useEffect(() => {
  //   setState(product || {});
  // }, [product]);

  const getOriginalPrice = useCallback(
    (price) => {
      if (!price || !hotel) {
        return 0;
      }
      const calTax = (Number(price) * 3) / 23;
      // console.log("call tax", calTax);
      const tax =
        hotel.tax_exclusive === 1 ? Number(price) - calTax : Number(price);
      // console.log("taxxxxxxxxx", tax);
      return tax.toFixed(2) || 0;
    },
    [hotel]
  );

  const getItemPrice = (item) => {
    let price = item?.sale_price;

    if (item?.size) {
      const sizeItem = item?.sizes?.find((it) => it.id === item?.size);
      if (sizeItem) {
        price = sizeItem.size_amount;
      }
    }

    return price;
  };

  const calculateDiscountedPrice = (originalPrice, discountPercentage) => {
    if (discountPercentage === 100) {
      return "0.00"; // If discount percentage is 100%, the price is 0
    }
    const discountedPrice = originalPrice * (1 - discountPercentage / 100);
    return discountedPrice;
  };

  const getDiscountedPrice = useCallback((item) => {
    if (item?.itemDiscountEnabled) {
      if (item?.itemDiscount) {
        const itemPrice = getItemPrice(item);

        const discount = selectedCoupon?.result?.DISCOUNT;
        const discountPrice = calculateDiscountedPrice(itemPrice, discount);
        return discountPrice;
      }
    }
  });

  return {
    getOriginalPrice,
    getDiscountedPrice,
  };
  // return {
  //     ...state,
  //     sale_price : originalPrice
  // }
}

export default useProduct;
