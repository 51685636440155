import * as types from "./ProfileTypes";

export const getCustomerOrderRequest = (payload) => ({
  type: types.GET_CUSTOMER_ORDER_REQUEST,
  payload,
});

export const getCustomerOrderReceive = (payload) => ({
  type: types.CUSTOMER_ORDER_RECEIVE,
  payload,
});

export const getCustomerOrderInProgress = (payload) => ({
  type: types.IS_CUSTOMER_ORDER_INPROGRESS,
  payload,
});

export const getCustomerOrderByPageNo = (payload) => ({
  type: types.GET_CUSTOMER_HISTORY_BY_PAGE_NO,
  payload,
});

export const setRedumptionRatio = (payload) => ({
  type: types.SET_REDUMPTION_RATIO,
  payload,
});

export const setUsedWalletPoint = (payload) => ({
  type: types.SET_USED_WALLET_POINT,
  payload,
});

export const setPriceWalletPoint = (payload) => ({
  type: types.SET_PRICE_WALLET_POINT,
  payload,
});

export const clearUsedWalletPoints = () => ({
  type: types.CLEAR_USED_WALLET_POINTS,
});

export const updateUsedWalletPoints = (payload) => ({
  type: types.UPDATE_USED_WALLET_POINTS,
  payload,
});

export const addUsedWalletPoints = (payload) => ({
  type: types.ADD_USED_WALLET_POINTS,
  payload,
});

export const resetWallet = () => ({
  type: types.RESET_WALLET,
});

export const setSelectedWallets = (payload) => ({
  type: types.SET_SELECTED_WALLETS,
  payload: payload,
});

export const resetSelectedWallets = (payload) => ({
  type: types.RESET_SELECTED_WALLETS,
});
