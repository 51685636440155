import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    position: "relative",
    // display: "flex",
    // flexDirection: "column",
    backgroundColor: theme.palette.common.white,
  },
  detailsContianer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      overflowY: "auto",
      overflowX: "hidden",
      width: "100%",
    },
  },
  scrollContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      overflowY: "hidden",
    },
  },
  closeButton: {
    boxSizing: "border-box",
    zIndex: 100,
    position: "absolute",
    padding: theme.spacing(0.4),
    top: theme.spacing(3.2),
    right: theme.spacing(3.2),
    width: 32,
    height: 32,
    borderRadius: "50%",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
  skipBtnDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  skipBtn: {
    margin: "0 1rem",
    color: "#fff",
    width: "200px",
    backgroundColor: "black",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#333",
    },
  },
  title: {
    ...theme.typography.rockwell,
    color: theme.palette.common.normalTitle,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: "23.49px",
    marginBottom: theme.spacing(0.8),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1.6),
    },
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    lineHeight: "24px",
    color: theme.palette.common.normalTitle,
    marginBottom: theme.spacing(0.8),

    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(0.5),
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    lineHeight: "24px",
    color: theme.palette.common.subTitle,
    // marginBottom: theme.spacing(0.8),

    // [theme.breakpoints.up("sm")]: {
    //   marginBottom: theme.spacing(1.6),
    // },
  },
  listCont: {
    padding: "16px",
    backgroundColor: theme.palette.common.lighterBg,
    marginBottom: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  listContDis: {
    padding: "16px",
    backgroundColor: theme.palette.common.lighterBg,
    marginBottom: "5px",
    // cursor: "pointer",
  },
  couponLeftDiv: {
    display: "flex",
    flexDirection: "column",
  },
  redeemText: {
    opacity: "0.5",
    color: theme.palette.common.normalTitle,
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 1.6, 1.6, 1.6),
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3.3, 2.4, 2.7, 2.4),
    },
  },
  entercoupon: {
    display: "flex",
    justifyContent: "center",
    margin: "1rem 0",
    marginTop: "2rem",
  },
  label: {
    color: theme.palette.common.normalTitle,
  },
  couponInput: {
    margin: "0 1rem",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.common.fontTitle, // Change this color to your desired color
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main, // Change this color to your desired color on hover
      },
    },
  },
  couponBtn: {
    margin: "0 1rem",
    color: theme.palette.common.normalTitle,
    "&.Mui-disabled": {
      color: theme.palette.common.fontTitle, // Change this color to your desired color for the disabled state
    },
  },
}));

export default useStyles;
