// Icons
import CloseIcon from "@material-ui/icons/Close";
// Material
import Typography from "@material-ui/core/Typography";

import IconBtn from "../../Controls/IconButton/IconButton";
import useStyles from "./CouponList.style";
import { useDispatch, useSelector } from "react-redux";
import {
  // getCoupon,
  selectCoupon,
  updateCart,
  validateCoupon,
} from "../../../store/Cart/CartActions";
import { useEffect, useMemo, useState } from "react";
import { TextField, Button } from "@material-ui/core";
import { resetWallet } from "../../../store/Profile/ProfileActions";
import useWalletPoints from "../../../CustomHooks/useWalletPoints";
import SpecialAlert from "../../SpecialAlert/SpecialAlert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CouponList = ({ handleClose = () => {}, coupons, handleSkip }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [manualCode, setManualCode] = useState("");
  const { hotel, hotelKey } = useSelector((state) => state.hotel);
  const {
    selectedCoupon,
    coupons: allCoupons,
    data,
  } = useSelector((state) => state.cart);

  const { menuVersion, sortedCategoryMenu } = useSelector(
    (state) => state.menu
  );
  const { resetWalletProducts } = useWalletPoints();
  const [itemModal, setItemModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const getUniqueCoupons = useMemo(() => {
    return allCoupons.filter(
      ({ id: id1 }) => !coupons.some(({ id: id2 }) => id2 === id1)
    );
  }, [allCoupons, coupons]);

  const couponClickHandler = (c) => {
    dispatch(resetWallet());
    resetWalletProducts(2);
    if (c?.result?.type === "ITEM_DISCOUNT") {
      setSelectedProducts(c);
      setItemModal(true);
    } else {
      dispatch(selectCoupon(c));
    }
    // handleClose(true);
  };
  // const availCouponClickHandler = (code) => {
  //   const payload = {
  //     hotelId: hotel.hotelId,
  //     code: code,
  //   };
  //   dispatch(validateCoupon(payload));
  // };

  const applyCoupon = () => {
    const payload = {
      hotelId: hotel.hotelId,
      code: manualCode,
    };
    dispatch(validateCoupon(payload));
  };

  useEffect(() => {
    if (selectedCoupon?.id) {
      handleClose(true);
    }
  }, [selectedCoupon]);

  const alertTitle =
    JSON.parse(selectedProducts?.items ?? "[]") &&
    JSON.parse(selectedProducts?.items ?? "[]")?.length > 0 &&
    JSON.parse(selectedProducts?.items ?? "[]")?.length > 1
      ? `You can get ${selectedProducts?.items
          ?.slice(0, -1)
          .map((product) => product?.name)
          .join(", ")} and ${
          selectedProducts?.products?.slice(-1)?.[0]?.name
        } free`
      : `You can get ${selectedProducts?.products?.[0]?.name} free`;

  const handleCloseItem = () => {
    if (selectedProducts?.items?.length > 0) {
      sortedCategoryMenu?.forEach((category) => {
        // Iterate through the items array of each category

        category?.item?.forEach((it) => {
          // Check if the item's itemId is included in the items array of any product in selectedCoupon
          const selectedItemArray = JSON.parse(selectedProducts?.items);
          const isItemIdIncluded = selectedItemArray?.includes(it?.itemId);
          // If the item's itemId is included in any product's items array in selectedCoupon, dispatch an action to update the cart
          const isTheItemInCart = selectedItemArray.some((item) =>
            data?.some((dataItem) => dataItem.itemId === item)
          );
          if (isItemIdIncluded) {
            dispatch(
              updateCart({
                ...it, // Pass the selected item's details
                count: 1,
                itemDiscountEnabled: true,
                itemDiscount: selectedProducts?.result?.DISCOUNT || 0,
                isEditMode: false,
                menuVersion,
                isAutoAdded: true,
                categoryId: category.categoryId, // Assuming categoryId is available in the selected item
              })
            );
          }
        });
      });
    }
    dispatch(selectCoupon(selectedProducts));
    setItemModal(false);
  };

  return (
    <div className={classes.root}>
      <IconBtn
        aria-label="close"
        component="span"
        icon={<CloseIcon />}
        onClick={() => handleClose(false)}
        className={classes.closeButton}
      />
      <div className={classes.scrollContainer}>
        <div className={classes.detailsContianer}>
          <div className={classes.infoContainer}>
            {/* <Typography className={classes.title} variant="h3">
              Available Coupons
            </Typography> */}
            <div className={classes.entercoupon}>
              <TextField
                className={classes.couponInput}
                // color="primary"
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                label="Enter Coupon Code"
                variant="outlined"
                onChange={(e) => {
                  setManualCode(e.target.value);
                }}
              />
              <Button
                className={classes.couponBtn}
                variant="contained"
                color="primary"
                onClick={applyCoupon}
                disabled={!manualCode}
              >
                APPLY
              </Button>
            </div>
            <Typography className={classes.title} variant="h3">
              Available Coupons
            </Typography>
            {coupons && coupons.length !== 0 ? (
              <>
                {coupons?.map((c) => (
                  <div
                    className={classes.listCont}
                    onClick={() => couponClickHandler(c)}
                    key={c.id}
                  >
                    <div className={classes.couponLeftDiv}>
                      <Typography className={classes.subTitle} variant="h3">
                        {c.name || ""}
                      </Typography>
                      <Typography className={classes.description} variant="h3">
                        {c?.description || ""}
                      </Typography>
                    </div>
                    <Typography className={classes.redeemText} variant="body1">
                      Click to Redeem
                    </Typography>
                    <Button
                      className={classes.couponBtn}
                      variant="contained"
                      // color="primary"
                      onClick={() => couponClickHandler(c)}
                      disabled={manualCode}
                    >
                      APPLY
                    </Button>
                  </div>
                ))}
              </>
            ) : (
              <Typography
                className={`text-center pt-2 ${classes.description}`}
                variant="h3"
              >
                No coupons available
              </Typography>
            )}
            {getUniqueCoupons.length !== 0 && (
              <>
                <Typography className={`py-2 ${classes.subTitle}`} variant="h3">
                  Other Coupons
                </Typography>
                {getUniqueCoupons?.map((c) => (
                  <div
                    className={classes.listContDis}
                    // onClick={(c) => availCouponClickHandler(c?.id)}
                    key={c.id}
                  >
                    <Typography className={classes.subTitle} variant="h3">
                      {c.name || ""}
                    </Typography>
                    <Typography className={classes.description} variant="h3">
                      {c?.description || ""}
                    </Typography>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className={classes.skipBtnDiv}>
            <Button className={classes.skipBtn} onClick={handleSkip}>
              Skip without Coupon
            </Button>
          </div>
        </div>
      </div>
      <SpecialAlert
        open={itemModal}
        title={alertTitle}
        handleClose={handleCloseItem}
      />
    </div>
  );
};

export default CouponList;
